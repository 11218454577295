import Vue from 'vue';
import { mapActions } from 'vuex';
import { Tab, Tabs } from 'vant';
import { Search } from 'vant';
import { Calendar } from 'vant';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import { Empty } from 'vant';

Vue.use(Empty);
// 全局注册
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Calendar);
Vue.use(Search);
Vue.use(Tab);
Vue.use(Tabs);

export default  {
  beforeRouteLeave(to, from, next){
    if(to.name == "paySuccess"){
       this.$router.push({name:"Mine"});
    }else{
      next();
    }
  },
  data() {
    return {
      empty:false,
      lists:[ ],
      activeName: 'a',
      value: '',
      // date: '',
      show: false,
      minDate: new Date(2021, 0, 1),
      same:true,
      maxDate:new Date(),
      flChildOrderState:'',
    };
  },

  created() {
    this.activeName=localStorage.getItem('active')
    this.getLists();
    localStorage.removeItem('active');


  },
  methods: {
    ...mapActions('warranty', ['myFlOrder','queryorder']),
    toDetail(flChildOrderNo,ddressId,OrderState){
      localStorage.setItem('orderState',OrderState)
      localStorage.setItem('orderId',flChildOrderNo)
        this.$router.push({
          name: 'orderDetail',
          query: {
            name:this.activeName,
            state:OrderState,
            id:flChildOrderNo,
            // addressId:ddressId
          }
        })



    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      var datas={
        startTime:this.formatDate(start),
        endTime:this.formatDate(end),
        flChildOrderState:this.flChildOrderState
      }

      this.myFlOrder(datas).then((res) => {
        this.lists=res.data;
        if (res.code == 200){
        }
      });
    },
    back(){
    if(this.$route.query.order==0){
      this.$router.go(-1);
    }else{
       this.$router.push({name:'Mine'})
    }

    },
    //已完成
    type(name,title){
      this.value=''
      if(title=='已完成'){
           this.flChildOrderState=3
           var data={
            flChildOrderState:this.flChildOrderState
          }
           }else if(title=='待支付'){
            this.flChildOrderState=0
            var data={
              flChildOrderState:this.flChildOrderState
            }
           }else if(title=='待发货'){
            this.flChildOrderState=1
            var data={
              flChildOrderState:this.flChildOrderState
            }
           }else if(title=='待收货'){
            this.flChildOrderState=2
            var data={
              flChildOrderState:this.flChildOrderState
            }
           }
      this.myFlOrder(data).then((res) => {
        this.lists=res.data;
        if (res.code == 200){
        }
      });
    },
    onInput(val) {
      var data={
        // flChildOrderState:flChildOrderState,
        flChildOrderProductName:val,
        flChildOrderState:this.flChildOrderState,

      }
      this.myFlOrder(data).then((res) => {
        this.lists=res.data;
        if (res.code == 200){

        }
      });

    },
    // },
    getLists(){
      // this.activeName=this.$route.query.name
      if(this.activeName=='a'){
        this.flChildOrderState=''
      }else if(this.activeName=='b'){
        this.flChildOrderState=0
      }else if(this.activeName=='c'){
        this.flChildOrderState=1
      }else if(this.activeName=='d'){
        this.flChildOrderState=2
      }else if(this.activeName=='e'){
        this.flChildOrderState=3
      }

      var data={
        flChildOrderState:this.flChildOrderState
      }
      this.myFlOrder(data).then((res) => {
        this.lists=res.data;
        if (res.code == 200){
        }
      });
    },

  },
}

